/* scrollbar.css */

/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 4px; /* Reduced width for a thinner scrollbar */
    height: 4px; /* Reduced height for a thinner scrollbar (for horizontal scrollbars) */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
    border-radius: 4px; /* Rounded corners for the track */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners for the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
  }
  
  /* For Firefox */
  * {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #888 #f1f1f1; /* thumb and track color */
  }